import React, { Component } from "react"
import Windowshade from "../windowshade/Windowshade"
import Collapsible from "../collapsible/Collapsible"

/* mobile charts */
import PCEChart from "../../images/graphs/mobile/34RRRmobile.png"
import PCEChartGraph from "../../images/graphs/mobile/time_to_first_coronary_revascularization_mobile.png"
import ReductionsChart from "../../images/graphs/mobile/coronary_revascularization_chart_mobile.png"
import Time_to_PCI_mobile from "../../images/graphs/mobile/Time_to_PCI_mobile.png"
import Time_to_PCI_percent from "../../images/graphs/mobile/Time_to_PCI_percent.jpg"
import Time_to_CABG_mobile from "../../images/graphs/mobile/Time_to_CABG_mobile.png"
import Time_to_CABG_percent from "../../images/graphs/mobile/Time_to_CABG_percent.png"

/* desktop charts */
import PCEChart_desk from "../../images/graphs/desktop/desktop_34.jpg"
import ReductionsChart_desk from "../../images/graphs/desktop/desktop_32_38_34.png"
import Time_to_PCI from "../../images/graphs/desktop/Time_to_PCI.jpg"
import Time_to_CABG from "../../images/graphs/desktop/Time_to_CABG.png"

import Magnify_icon from "../../images/magnify-icon.svg"

import { Link } from "gatsby"
import "./styles.css"

const CollapseMobile = props => {
  return (
    <Collapsible
      title={[
        "VASCEPA CV Outcomes Trial",
        <br key="2" />,
        "(REDUCE-IT",
        <sup key="1" className="sup-align-top-2">
          ®
        </sup>,
        ") Design",
        <sup key="3">1</sup>,
      ]}
      collapsibleWidth={"collapsible512 m-center"}
    >
      <p className="body-copy">
        A randomized, double-blind, placebo-controlled trial for adults with
        eCVD or with diabetes and other risk factors, receiving statin therapy,
        and with modestly elevated TG (median baseline 216 mg/dL) and
        well-controlled LDL-C (median baseline 75 mg/dL). 8179 patients (VASCEPA
        4 g/d [n=4089]; placebo [n=4090]) were followed for a median of 4.9
        years.
      </p>
      <p style={{ paddingTop: "20px" }} className="body-copy">
        Patients in the VASCEPA CV Outcomes Trial (REDUCE-IT) received
        standard-of-care medications including statins (100%), antihypertensives
        (95%), antiplatelet medication (79%), ACE inhibitors (52%), ARBs (27%),
        and beta blockers (71%).
      </p>
    </Collapsible>
  )
}

const CollapseDesk = props => {
  return (
    <Collapsible
      title={[
        "VASCEPA CV Outcomes Trial (REDUCE-IT",
        <sup key="1" className="sup-align-top-2">
          ®
        </sup>,
        ") Design",
        <sup key="3">1</sup>,
      ]}
      collapsibleWidth={"collapsible512 m-center"}
    >
      <p className="body-copy">
        A randomized, double-blind, placebo-controlled trial for adults with
        eCVD or with diabetes and other risk factors, receiving statin therapy,
        and with modestly elevated TG (median baseline 216 mg/dL) and
        well-controlled LDL-C (median baseline 75 mg/dL). 8179 patients (VASCEPA
        4 g/d [n=4089]; placebo [n=4090]) were followed for a median of 4.9
        years.
      </p>
      <p style={{ paddingTop: "20px" }} className="body-copy">
        Patients in the VASCEPA CV Outcomes Trial (REDUCE-IT) received
        standard-of-care medications including statins (100%), antihypertensives
        (95%), antiplatelet medication (79%), ACE inhibitors (52%), ARBs (27%),
        and beta blockers (71%).
      </p>
    </Collapsible>
  )
}

export default props => {
  function goModal(evt) {
    const dataAtt = evt.target.dataset.modal
    const dataHref = evt.target.dataset.href || null
    props.goModal(dataAtt, dataHref)
  }

  // conditional render see components above
  const isMobile = props.isMobile
  let collapse
  if (isMobile) {
    collapse = <CollapseMobile />
  } else {
    collapse = <CollapseDesk />
  }

  return (
    <main className="main">
      <section id="section-1" className="endpoint-outcome-sec-one cr-data">
        <h2  className="cv-headline d-m-l-0">
          VASCEPA significantly reduced the need for{" "}
          <span className="bold700">
            coronary revascularizations across first and total events
            <sup>2,3</sup>
          </span>
        </h2>
        <div className="windowshade-container">
          <div className="windowshade-header">
            <p className="">See Graph</p>
          </div>
          <Windowshade id={"first_coronary_revascularization"}>
            <div className="shade-copy-container">
              <p className="shade-body-copy">34% RRR;&nbsp;NNT=24</p>
              <p className="shade-body-copy-alt">
                ARR=4.1%; <em>P</em>=0.0000000008
              </p>
            </div>
            <div className="windowshade-chartheadline-container">
              <h3 className="windowshade-chartheadline magenta">
                Time to first coronary revascularization
              </h3>
              <img
                className="magnify_icon"
                data-modal="time_to_first_coronary_revascularization"
                src={Magnify_icon}
                onClick={goModal}
                alt=""
              />
            </div>
            <img className="chart" src={PCEChartGraph} alt="" />

            <div className="windowshade-footer">
              <p className="">close</p>
            </div>
          </Windowshade>
        </div>
        <div  className="chart-container-centering d-m-l-0">
          <div className="chart-container">
            <h4 className="cv-chartheadline magenta mobile-text-center">
              Time to first coronary revascularization
            </h4>
            <div className="chartlockup-outcomes">
              <img
                className="chart m-chart-center mobile-w-160px m-b-10"
                src={props.isMobile ? PCEChart : PCEChart_desk}
                alt=""
              />
              {props.isMobile ? null : (
                <img
                  className="magnify_icon"
                  data-modal="time_to_first_coronary_revascularization"
                  src={Magnify_icon}
                  onClick={goModal}
                  alt=""
                />
              )}
            </div>
            
            <p className="cv-chart-footnote">
            Adapted from <em>Circulation</em>, Vol{" "}
            143, Peterson BE, Bhatt DL, Steg PG,
            et al. Reduction in revascularization with icosapent ethyl: insights from REDUCE-IT
            Revascularization Analyses, pp 33-44. Copyright 2021,
            with permission from Lippincott Williams & Wilkins.
            </p>
            <p className="cv-chart-footnote">
              At Year 5 since randomization, 1521 patients remained in the
              VASCEPA arm vs 1431 patients in the placebo arm.
            </p>
            <p className="cv-chart-footnote">
              ARR=absolute risk reduction; NNT=number needed to treat; RRR=relative risk reduction.
            </p>
            <p className="cv-chart-footnote">
              The curves were visually truncated at 5.7 years because a limited
              number of events occurred beyond that time point; all patient data
              were included in the analyses.
            </p>
            <p className="cv-chart-footnote">
              ARR is based on the observed rates of events of 9.2% for VASCEPA
              and 13.3% for placebo.
            </p>
          </div>

          <div className="chart-sidebar">
            <ul className="content-list">
              <li className="primary-list-item">
                The CV event curve for VASCEPA separated from
                the placebo event curve at 11 months and remained separated
                throughout the follow-up period
              </li>
            </ul>
          </div>
        </div>

        <div  className="outcomes-callout d-m-l-0">
          {/* {collapse} */}
          <div className="grad-border">
            <p className="callout-copy">
              VASCEPA also significantly reduced the time to total
              revascularization events by 36%; <em>P</em>=0.0000000005
              <sup className="callout-sup">2,3</sup>
            </p>
          </div>
          <p className="body-copy mb-25">
            REDUCE-IT<sup>&reg;</sup> was not specifically powered to examine
            individual cardiovascular endpoints; therefore, <em>P</em> values{" "}
            presented for these revascularization analyses are nominal and
            exploratory with no adjustment for multiple comparisons. In
            addition, coronary revascularization as an endpoint can sometimes be
            considered subjective; however, these endpoints were adjudicated by
            an independent, blinded clinical endpoint committee. Results from
            the total coronary revascularization events analyses are consistent
            across the various recurrent event statistical models and are also
            consistent with the first coronary revascularization events results.
            Together, the REDUCE-IT first and total coronary revascularization
            events results support the robustness and consistency of the
            clinical benefit of VASCEPA therapy in reducing coronary
            revascularization.
          </p>
          <div className="cta-container m-center fit-content">
          <h3 className="cta-copy">See the data in <em>Circulation</em></h3>
          <button
            data-modal="leavingsite-modal"
            data-href="https://www.ahajournals.org/doi/epub/10.1161/CIRCULATIONAHA.120.050276"
            onClick={goModal}
            className="cta-btn"
          >
              View now
            </button>
          </div>
        </div>
      </section>
      <hr />

      <section id="section-2" className="endpoints">
        <h2 className="cv-headline">
          Reduction in revascularization was{" "}
          <span className="bold700">
            consistent across elective, emergent, and urgent events<sup>2,3</sup>
          </span>
        </h2>
        <div className="chart-container-centering">
          <div className="chart-container">
            <div className="chartlockup CR-chart">
              <img
                className="chart"
                src={props.isMobile ? ReductionsChart : ReductionsChart_desk}
                alt=""
              />
              <img
                className="magnify_icon"
                data-modal="chart-1.5-2"
                src={Magnify_icon}
                onClick={goModal}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <hr />

      <section
        id="section-3"
        className="endpoints endpoints-section-last no-bottom-border"
      >
        <h3 className="cv-eyebrow dkgray bold700">
          In a <em>post-hoc</em> analysis,
        </h3>
        <h2 className="cv-headline">
          VASCEPA was observed
          <span className="bold700"> to reduce PCI and CABG</span>
          <sup>2,3</sup>
        </h2>

        <div className="chart-container-centering m-flex">
          <div className="chart-container m-order-2">
            <div className="windowshade-container">
              <div className="windowshade-header">
                <p className="">See Graph</p>
              </div>
              <Windowshade id={"Time_to_PCI"}>
                <div className="shade-copy-container">
                  <p className="shade-body-copy">32% RRR</p>
                  <p className="shade-body-copy-alt">
                    <em>P</em>=0.0000002
                  </p>
                </div>
                <div className="windowshade-chartheadline-container">
                  <h3 className="windowshade-chartheadline magenta">Time to PCI</h3>
                  <img
                    className="magnify_icon"
                    data-modal="Time_to_PCI"
                    src={Magnify_icon}
                    onClick={goModal}
                    alt=""
                  />
                </div>
                <img className="chart" src={Time_to_PCI_mobile} alt="" />

                <div className="windowshade-footer">
                  <p className="">close</p>
                </div>
              </Windowshade>
            </div>
            <div id="Time_to_PCI_under" className="windowshade-under">
              <h4 className="cv-chartheadline magenta mobile-text-center">Time to PCI</h4>
              <div className="chartlockup">
                <img
                  className="chart mobile-align-center mobile-w-132px"
                  src={props.isMobile ? Time_to_PCI_percent : Time_to_PCI}
                  alt=""
                />
                <img
                  className="magnify_icon"
                  data-modal="Time_to_PCI"
                  src={Magnify_icon}
                  onClick={goModal}
                  alt=""
                />
              </div>
              <p className="cv-chart-footnote mb-30">
                At Year 5 since randomization, 1548 patients remained in the
                VASCEPA arm vs 1474 patients in the placebo arm.
              </p>
            </div>
            <div className="windowshade-container">
              <div className="windowshade-header">
                <p className="">See Graph</p>
              </div>
              <Windowshade id={"Time_to_CABG"}>
                <div className="shade-copy-container">
                  <p className="shade-body-copy">39% RRR</p>
                  <p className="shade-body-copy-alt">
                    <em>P</em>=0.0005
                  </p>
                </div>
                <div className="windowshade-chartheadline-container">
                  <h3 className="windowshade-chartheadline magenta">Time to CABG</h3>
                  <img
                    className="magnify_icon"
                    data-modal="Time_to_CABG"
                    src={Magnify_icon}
                    onClick={goModal}
                    alt=""
                  />
                </div>
                <img className="chart" src={Time_to_CABG_mobile} alt="" />

                <div className="windowshade-footer">
                  <p className="">close</p>
                </div>
              </Windowshade>
            </div>
            <div className="windowshade-under">
              <h4 className="cv-chartheadline magenta mobile-text-center" style={{marginTop: "30px"}} >Time to CABG</h4>
              <div className="chartlockup">
                <img
                  className="chart mobile-align-center mobile-w-100px"
                  src={props.isMobile ? Time_to_CABG_percent : Time_to_CABG}
                  alt=""
                />
                <img
                  className="magnify_icon"
                  data-modal="Time_to_CABG"
                  src={Magnify_icon}
                  onClick={goModal}
                  alt=""
                />
              </div>
              <p className="cv-chart-footnote">
                At Year 5 since randomization, 1659 patients remained in the
                VASCEPA arm vs 1598 patients in the placebo arm.
              </p>
              <p className="cv-chart-footnote">
              Adapted from <em>Circulation</em>, Vol{" "}
              143, Peterson BE, Bhatt DL, Steg
              PG, et al. Reduction in revascularization with
              icosapent ethyl: insights from
              REDUCE-IT Revascularization Analyses, pp 33-44.
              Copyright 2021, with permission from{" "}
              Lippincott Williams & Wilkins.
              </p>
              <p className="cv-chart-footnote">
                CABG=coronary artery bypass graft; PCI=percutaneous coronary
                intervention.
              </p>
              <p className="cv-chart-footnote">
                The curves were visually truncated at 5.7 years because a
                limited number of events occurred beyond that time point; all
                patient data were included in the analyses.
              </p>
            </div>
          </div>

          <div className="chart-sidebar m-order-1">
            <ul className="content-list mobile-list-margin">
              <li className="primary-list-item">
                The CV event curves for VASCEPA appear to visually separate from
                the placebo event curve at approximately 1 year and remained
                separated throughout the follow-up period
              </li>
            </ul>
          </div>
        </div>

        <div className="outcomes-callout cr-callout">
          <div className="cta-container m-center fit-content">
            <h3 className="cta-copy">Get patients started on VASCEPA</h3>
            <Link to="/get-vascepa-now/" className="cta-btn">
              Learn more
            </Link>
          </div>
        </div>
      </section>
    </main> /* end main*/
  )
}
